import React, { Component } from "react"
import Formsy from 'formsy-react';
import { connect } from "react-redux"
import { navigate } from "gatsby"

import Layout from "../components/layout"
import Steps from "../components/steps"
import bottomLine from "../images/line.png"
import SEO from "../components/seo"
import * as actionCreators from './../store/actions';
import MyInput from "../components/MyInput"
import MyAuto from "../components/MyAuto";
import MyCheckbox from "../components/MyCheckbox";
import MyInputMask from "../components/MyInputMask";
import ButtonLoader from "../components/buttonLoader";
import { zonar } from './../components/constant'

class Agre1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canSubmit: false,
      agre1Data:{name:'',street:'',city:'',state:'',zip:'',phone:'',email:'',taxId:'',website:'',bo_name:'',print_name:'',title:'',dealGrp:false}
    };
  }

  componentDidMount = async () => {
    const { showLoader, hideLoader } = this.props;
    if(!sessionStorage.version || sessionStorage.version!==process.env.signup_lsid){
      sessionStorage.clear();
      sessionStorage.version=process.env.signup_lsid;
    }
    if(!sessionStorage.agre1Data){
      sessionStorage.agre1Data = window.btoa(JSON.stringify(this.state.agre1Data))
    } 
    this.state.agre1Data = JSON.parse(window.atob(sessionStorage.agre1Data))
    this.setState({agre1Data:JSON.parse(window.atob(sessionStorage.agre1Data))});
    
    showLoader();
    hideLoader();
    
  }

  sugChange = async(value,type) =>{
    let agre1Data = this.state.agre1Data;
    agre1Data.state = value;
    this.setState({agre1Data:agre1Data});
    this.enableButton();
  }

  disableButton = () => {
    this.setState({ canSubmit: false });
  }
  enableButton = () => {
    if(this.state.agre1Data.state)
    this.setState({ canSubmit: true,infoError:"" });
    else
    this.setState({ canSubmit: false,infoError:"" });
  }
  submit = (model) => {
    const { showLoader, hideLoader} = this.props;
    showLoader();
    
    model.dealGrp = this.state.agre1Data.dealGrp;
    model.state = this.state.agre1Data.state;
    sessionStorage.agre1Data = window.btoa(JSON.stringify(model));
    
    hideLoader();
    navigate("/agreement2"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''))
  }
  
  featureChanges = (value) => {
    let agre1Data = this.state.agre1Data;
    agre1Data.dealGrp = value;
    this.setState({ agre1Data: agre1Data })
    this.state.agre1Data = agre1Data;
  }
   
  render() {
    const { agre1Data, canSubmit } = this.state;
    const { buttonLoader} = this.props;
    
    return (
      <Layout layoutType="wizard" >
        <SEO title="Dealer Agreement" keywords={[`dealer`, `agreement`, `contacts`]} />
        <section>
          <Steps></Steps>
          <div className="custom-container vehinfo">
            <div className="title-container">
              {/* <div className="icon-image">
                <img src={vehicalIcon} alt="icon" />
              </div> */}
              <div className="title-text">
                <h1>PRODUCER INFORMATION</h1>
                <p></p>
              </div>
            </div>

            <div className="body-content">
              <Formsy ref="agre1Form" onValidSubmit={this.submit} onValid={this.enableButton} onInvalid={this.disableButton}>

              <div className="form-container-inline">
                    <div className="form-left">
                        <div className="form-label-group error">
                            <MyInput name="name" id="name" placeholder="Dealership Name"  value={agre1Data.name} validationError="Please enter a valid name."
                                isLabelRequired={true} className="form-control" validations={{minLength: 3,maxLength:25}} required />
                        </div>

                    </div>
                    <div className="form-right">
                        <div className="form-label-group error">
                        <MyInput name="street" id="street" placeholder="Street Address"  value={agre1Data.street} validationError="Please enter a valid street."
                                isLabelRequired={true} className="form-control" validations={{minLength: 3,maxLength:25}} required />
                        </div>
                    </div>
                </div>
                <div className="form-container-inline">
                    <div className="form-left">
                        <div className="form-label-group error">
                            <MyInput name="city" id="city" placeholder="City"  value={agre1Data.city} validationError="Please enter a valid city."
                                isLabelRequired={true} className="form-control" validations={{minLength: 3,maxLength:25}} required />
                        </div>

                    </div>
                    <div className="form-right">
                        <div className="form-label-group error">
                        <label>State*</label>
                        <MyAuto value={agre1Data.state} options={zonar}  sugChange={(e) => this.sugChange(e, 'zone')} name="zone" />
                        </div>
                    </div>
                </div>

                <div className="form-container-inline">
                    <div className="form-left">
                        <div className="form-label-group error">
                        <MyInputMask name="zip" id="zip" placeholder="Zip Code" mask="99999" value={agre1Data.zip} isLabelRequired={true}
                            className="form-control" validations={{minLength: 5}} validationError="Please enter a valid zipcode." required />
                        </div>

                    </div>
                    <div className="form-right">
                        <div className="form-label-group error">
                        <MyInputMask name="phone" id="phone" placeholder="Phone" mask="999-999-9999" value={agre1Data.phone} isLabelRequired={true}
                            className="form-control" validations={{minLength: 12,maxLength:15}} validationError="Please enter a valid Phone." required />
                        
                        </div>
                    </div>
                </div>

                <div className="form-container-inline">
                    <div className="form-left">
                        <div className="form-label-group error">
                        <MyInput name="bo_name" id="bo_name" placeholder="Business Owner Name" value={agre1Data.bo_name} validations={{minLength: 3,maxLength:25}}
                        isLabelRequired={true} className="form-control"  validationError="Please enter a valid name." required  />
                        </div>

                    </div>

                    <div className="form-right">
                        <div className="form-label-group error">
                        <MyInput name="email" id="email" placeholder="Email (Main Contact)"  value={agre1Data.email} validations="isEmail"
                                isLabelRequired={true} className="form-control" validationError="Please enter valid email" required />
                        </div>
                    </div>
                    
                    
                </div>

                <div className="form-container-inline">
                    <div className="form-left">
                        <div className="form-label-group error">
                        <MyInput name="title" id="title" placeholder="Title"  value={agre1Data.title} isLabelRequired={true} className="form-control" 
                        validations={{minLength: 1,maxLength:25}}  required  validationError="Please enter a valid title." />
                        </div>

                    </div>

                    <div className="form-right">
                        <div className="form-label-group error">
                        <MyInput name="sales_email" id="sales_email" placeholder="Sales Rep's Email" value={agre1Data.sales_email} isLabelRequired={true} 
                        className="form-control" required validations="isEmail" validationError="Please enter valid email"  />
                        </div>
                    </div>
                </div>

                <div className="form-container-inline">
                    <div className="form-left">
                        <div className="form-label-group error">
                        <MyInput name="taxId" id="taxId" placeholder="Dealership Tax ID" value={agre1Data.taxId} isLabelRequired={true} className="form-control"  />
                        
                        </div>

                    </div>

                    <div className="form-right">
                        <div className="form-label-group error">
                        <MyInput name="website" id="website" placeholder="Dealership Website"  value={agre1Data.website} isLabelRequired={true} className="form-control"  />
                        </div>
                    </div>
                </div>
                
                <div className="form-container-inline">
                    <div className="form-left">
                      <div className="form-check">
                        <MyCheckbox
                          name="dealGrp" id="cbx2" labelClass="check-container big dealGrp" value={agre1Data.dealGrp}
                          label="Part of a Dealer Group" checkBoxChange={(e) => this.featureChanges(e)}
                        />
                      </div>

                    </div>
                </div>

                <div className="btn-container">
                    <button type="submit" disabled={!canSubmit || buttonLoader} className={!canSubmit || buttonLoader ? 'btn disabled wizard-btn creat-btn' : 'btn wizard-btn creat-btn'}>
                        <ButtonLoader isLoading={buttonLoader} label={'Continue'} />
                    </button>
                </div>

              </Formsy>
              <div className="txtright">* Field is required</div>
            </div>
          </div>
          
          <div className="vehicle-bottom-line bottom-line">
            <img src={bottomLine} alt="line" />
          </div>
        </section>


      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    pageLoader: state.primary.pageLoader,
    buttonLoader: state.primary.buttonLoader
  }
};

const mapDispatchToProps = dispatch => {
  return {
    showLoader: () => dispatch(actionCreators.showLoader()),
    hideLoader: () => dispatch(actionCreators.hideLoader()),
    
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Agre1);