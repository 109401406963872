import React, { Component } from "react"
import { Link } from "gatsby";
import { connect } from "react-redux"

import leftarrow from "../images/left-arrow.svg"

const mysteps = {
    signup: 1,
    agreement2: 2,
    agreement4: 3,
    agreement5: 4,
}

class Steps extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentStep: 1,
        };
    }

    componentDidMount() {
        
        for (let key in mysteps) {
            if (window.location.pathname.includes(key)) {
                this.setState({ currentStep: mysteps[key] });
            }
        }
        // this.setState({ currentStep: WIZARD_STEPS[window.location.pathname.replace(/[^a-zA-Z ]/g, "")] })  // remove special character from pathname
        this.nameInput.focus();
    }

    render() {
        const { currentStep } = this.state;
        const { step2response } = this.props;
        // const backnavigation = currentStep > 1 && WIZARD_STEPS_NUMBER_WISE[currentStep - 1];
        let backnavigation = 'vehicleInfo';
        if (currentStep > 1) {
            Object.keys(mysteps).forEach((key) => {
                if (mysteps[key] === currentStep - 1) {
                    backnavigation = key;
                }
            });
        }
        let recordId = '';
        if (step2response && step2response.recordId) {
            recordId = `/?q=${step2response.recordId}`;
        }
        recordId = (global.dealertoken?'/?dealertoken='+global.dealertoken:'');
        return (
            <div className="wizrd-container">
                <input className="hidox" ref={(input) => { this.nameInput = input; }} />
                {
                    currentStep !== 1 && currentStep !== 6 &&
                    <Link to={`/${backnavigation}${recordId}`} state={{ isFromWizard: true }} >
                        <span className="left-arrow"><img src={leftarrow} alt={'leftarrow'} /></span>
                    </Link>
                }
                <ul>
                    <li className={currentStep === 1 ? 'active' : currentStep !== 6 ? 'complete' : ''} >
                        {currentStep !== 6 ?
                            <Link to={`/signup${recordId}`} state={{ isFromWizard: true }} >
                                <label>PRODUCER INFO</label>
                                <span>1</span>
                            </Link>
                            :
                            <>
                                <label>PRODUCER INFO</label>
                                <span>1</span>
                            </>
                        }
                    </li>
                    <li className={currentStep === 2 ? 'active' : currentStep > 2 && currentStep !== 6 ? 'complete' : ''}>
                        {
                            currentStep > 2 && currentStep !== 6
                                ?
                                <Link to={`/agreement2${recordId}`} state={{ isFromWizard: true }} >
                                    <label>{'PRIMARY CONTACT INFO'}</label>
                                    <span>2</span>
                                </Link>
                                :
                                <>
                                    <label>{'PRIMARY CONTACT INFO'}</label>
                                    <span>2</span>
                                </>
                        }

                    </li>
                    <li className={currentStep === 3 ? 'active' : currentStep > 3 && currentStep !== 6 ? 'complete' : ''}>
                        {
                            currentStep > 3 && currentStep !== 6
                                ?
                                <Link to={`/agreement4${recordId}`} state={{ isFromWizard: true }} >
                                    <label>DMS TYPE</label>
                                    <span>3</span>
                                </Link>
                                :
                                <>
                                    <label>DMS TYPE</label>
                                    <span>3</span>
                                </>
                        }

                    </li>
                    <li className={currentStep === 4 ? 'active' : currentStep > 4 && currentStep !== 6 ? 'complete' : ''}>
                        {
                            currentStep > 4 && currentStep !== 6
                                ?
                                <Link to={`/agreement5${recordId}`} state={{ isFromWizard: true }} >
                                    <label>COMMISSION PAYEE</label>
                                    <span>4</span>
                                </Link>
                                :
                                <>
                                    <label>COMMISSION PAYEE</label>
                                    <span>4</span>
                                </>
                        }

                    </li>
                    {/* <li className={currentStep === 5 ? 'active' : currentStep > 5 && currentStep !== 6 ? 'complete' : ''}>
                        {
                            currentStep > 5 && currentStep !== 6
                                ?
                                <Link to={`/agreement5`} state={{ isFromWizard: true }} >
                                    <label>COMMISSION PAYEE</label>
                                    <span>5</span>
                                </Link>
                                :
                                <>
                                    <label>COMMISSION PAYEE</label>
                                    <span>5</span>
                                </>
                        }

                    </li> */}
                    {/* <li className={currentStep === 6 ? 'active' : currentStep > 6 ? 'complete' : ''}>
                        <label>Confirm</label>
                        <span>6</span>
                    </li> */}
                </ul>
                <span className="line step5"></span>
            </div >
        );
    }
}


const mapStateToProps = state => {
    return {
        step2response: state.register.step2response
    }
};

const mapDispatchToProps = dispatch => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Steps);
